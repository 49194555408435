<template>
    <div class="container-evento">   
  <v-app  >
   
    <div class="col-md-12 col-lg-12 col-sm-12" >
          <div class="cont-desc-evento">
  
               
                <div class="w-100 px-5 d-flex mb-5" style="padding-top:5px;">
                    <button class="ml-auto btn btn-secondary" @click="$router.push({path: '/'})">Volver al inicio</button>
                </div>
                <div class="col-12 col-lg-12 order-2 order-md-1">
                    <div class="card-resumen">
                        <div class="table-responsive">
                          <table class="tabla_resumen table"  style="max-width:450px;">
                                <tr>
                                    <th colspan="2"><h2 class="my-4 w-100 text-center">Resumen transacción</h2></th>
                              </tr>
                                <tr>
                                    <td><div align="left"><b>Orden</b></div></td>
                                    <td><div align="left">{{ datos_compra.orden }}</div></td>
                                </tr>
                                <tr>
                                    <td><div align="left"><b>Nombre Cliente</b></div></td>
                                    <td><div align="left">{{ datos_compra.nombre_cliente }}</div></td>
                                </tr>
                                <tr>
                                    <td><div align="left"><b>Respuesta banco</b></div></td>
                                    <td v-if="datos_compra.res_banco == 1">
                                        <v-chip variant="flat" color="green" style="color: white;">
                                            <div align="left">Aprobado                                        </div>
                                        </v-chip>                                    </td>
                                    <td v-if="datos_compra.res_banco == 2">
                                        <v-chip variant="flat" color="red" style="color: white;">
                                            Rechazado                                        </v-chip>                                    </td>
                                    <td v-if="datos_compra.res_banco == 0">
                                        <v-chip variant="flat" color="primary" style="color: white;">
                                            Pendiente Aprobación                                        </v-chip>                                    </td>                                
                                </tr>                                
                                <tr>
                                    <td><div align="left"><b>Id de venta</b></div></td>
                                    <td><div align="left">{{ datos_compra.id_venta }}</div></td>
                                </tr>
                                <tr>
                                    <td><div align="left"><b>Fecha transacción</b></div></td>
                                    <td><div align="left">{{ datos_compra.fecha_transaccion }}</div></td>
                                </tr>                                                              
                                <tr>
                                    <td><div align="left"><b>Referencia orden</b></div></td>
                                    <td><div align="left">{{ datos_compra.orden }}</div></td>
                                </tr>
                                <tr>
                                    <td><div align="left"><b>Estado transacción</b></div></td>
                                    <td>
                                        <div align="left">{{ datos_compra.estado_transaccion }}                                    </div></td>
                                </tr>                                
                                <tr>
                                    <td><div align="left"><b>Subtotal compra</b></div></td>
                                    <td>
                                    <div align="right">{{   currencyFormatDE(datos_compra.bs_subtotal, ',','.',true) + ' Bs.S' }}                                    </div></td>
                                </tr>   
                                
                                <tr>
                                    <td><div align="left"><b>Servicio</b></div></td>
                                    <td>
                                    <div align="right">{{   currencyFormatDE(datos_compra.bs_servicio, ',','.',true) + ' Bs.S' }}                                    </div></td>
                                </tr>     

                                <tr v-if="datos_compra.bs_impuesto > 0">
                                    <td><div align="left"><b>ISEP </b></div></td>
                                    <td>
                                    <div align="right">{{   currencyFormatDE(datos_compra.bs_impuesto, ',','.',true) + ' Bs.S' }}                                    </div></td>
                                </tr> 

                                <tr v-if="datos_compra.bs_impuesto2 > 0">
                                    <td><div align="left"><b>IMP. MUNICIPAL </b></div></td>
                                    <td>
                                    <div align="right">{{   currencyFormatDE(datos_compra.bs_impuesto2, ',','.',true) + ' Bs.S' }}                                    </div></td>
                                </tr> 
                                
                                <tr v-if="datos_compra.bs_iva > 0">
                                    <td><div align="left"><b>IVA </b></div></td>
                                    <td>
                                    <div align="right">{{   currencyFormatDE(datos_compra.bs_iva, ',','.',true) + ' Bs.S' }}                                    </div></td>
                                </tr> 

                                <tr>
                                    <td><div align="left"><b>Total Compra </b></div></td>
                                    <td><div align="right">{{  currencyFormatDE(datos_compra.bs_total, ',','.',true) + ' BS.S ' }}</div></td>
                                </tr>
                            </table>

                            <br>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-lg-4 px-3 px-md-4 order-1 order-md-2">
                    <div class="card-resumen mb-3">
                        <h3 class="text-center w-100">Resumen de compra</h3>
                        <div class="divider-resumen"/> -->
                        <!-- <div style="border-bottom: 1px solid #9797973a" class="pb-2 d-flex align-items-center justify-content-between w-100 px-4 px-lg-2" :key="index" v-for="(product, index) in $store.state.responseDataPayment.response_transaction.productos">
                            <div>
                                <p class="mb-0">{{product.descripcion}}</p>
                                <small style="color: gray">Cantidad: {{product.cantidad}}</small> <br>
                                <small style="color: gray">Id: {{product.id}}</small>
                            </div>
                            <p class="mb-0 px-2"><b>{{product.precio}}</b></p>
                        </div> -->
                        <!-- <div class="d-flex justify-content-between ms-auto my-4 pe-3 w-100">
                            <p style="font-size: 18px">TOTAL: </p>
                            <p style="font-size: 18px"><b></b></p>
                        </div>
                    </div>
                </div> -->
            </div>
            
        </div>

      
    </v-app>

    </div>

</template>
<script> 
 
import moment from "moment";
import { faSleigh } from "@fortawesome/free-solid-svg-icons";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/l10n/es';
import 'flatpickr/dist/themes/material_blue.css'; 
import { asyncDispose } from 'core-js/fn/symbol';
import {configApi} from './../../../api/configApi'
import {headers} from './../../../api/headers'
import axios from 'axios'
moment.locale("es");

export default {
components: { 
 flatPickr, 
},
data() {
 return {
    datos_compra: {
                orden: '',
                res_banco: '',
                id_venta: '',
                fecha_transaccion: '',
                referencia_orden: '',
                estado_transaccion: '',
                subtotal_compra: '',
                total_compra: '',
                costo_transaccion: '',
                nombre_cliente: '',
                bs_subtotal: '',
                bs_servicio: '',
                bs_iva: '',
                bs_impuesto: '',
                bs_impuesto2: '',
                bs_total: '',
     
            },
   cambio_moneda: 'USD',
   type: 'month',
   start: new Date(),
   end: new Date(),
   typeOptions: [
     { text: 'Day', value: 'day' },
     { text: '4 Day', value: '4day' },
     { text: 'Week', value: 'week' },
     { text: 'Month', value: 'month' },
     { text: 'Custom Daily', value: 'custom-daily' },
     { text: 'Custom Weekly', value: 'custom-weekly' }
   ],
   selectedDate:  new Date(), 
   seleccionado: null,
   enabledDates: [ ],
   fechaselectedDate:'',
   seleccionadohora:'',
   menu: false, 
   acceptTyC: false,
   acceptTD: false,
   dialogotablaprecios: false,
   dataCompare: null,
   tab: "general",
   tabpreventa: 0,
   modalSelectEntregaBoletas: false,
   entregaBoletas: "digital",
   keyBoletasxLocalidad: 0,
   mostrarmapa: true,
   modalVisible: false,
   horario:[],  
   flatpickrStyle: {
     width: '500px',
     height: '70px',
   },
   name: '',
   cardNumber: '',
   expiration: '',
   security: '',
   translations: {
       name: {
           label: 'Nombre',
           placeholder: 'Nombre completo'
       },
       card: {
           label: 'Número de tarjeta',
           placeholder: 'Número de tarjeta'
       },
       expiration: {
           label: 'Expiration'
       },
       security: {
           label: 'Código de seguridad',
           placeholder: 'Código'
       }
   },
   
   
   keyPuntoventas: "",
   headersPuntoventa: [
     {
       text: "Nombre Comercial",
       align: "left",
       value: "punto_ventum.nombre_razon",
     },
     {
       text: "Dirección",
       value: "punto_ventum.direccion",
     },
     {
       text: "Ciudad",
       value: "punto_ventum.id_ciudad",
     },
     {
       text: "Zona/Region",
       value: "punto_ventum.zona",
     },
     {
       text: "Telefono",
       value: "punto_ventum.telefono",
     },
     {
       text: "Responsable",
       value: "punto_ventum.responsable",
     },
   ],
   divisaseleccionada: '$',
   rowperpageitems: [5, 10, 25, { text: "Todos", value: -1 }],
   idLocalidad: "",
   dialogmapasientos: false,
   elhtml2: "",
   monedaSelectShop: null, 
   activeModalAsientos: false, 
   datos_tarjeta: '',
   datos_p2p: '',
   datos_c2p: ''
 };
},
watch: {
 // evento(){
 //   this.$store.dispatch('auditorio_mapeado/listLocalidadesAuditorio_mapeado',this.evento.id_auditorio_mapeado);
 // },
 async evento() {
   this.$store.commit('evento/setImagenesEvento', null);
   if(this.evento){
     this.$store.dispatch(
       "evento/listPreventasEvento",
       this.evento.id
     );
     this.$store.dispatch(
       "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
       this.evento.id
     );
     await this.$store.dispatch("evento/changeMonedaShopEvento", {
       codigo_moneda: this.monedaSelectShopEvento,
       evento_id: this.evento.id,
     });
     this.$store.dispatch(
       "evento/getCondicionesEvento",
       this.evento.id
     );
     this.$store.dispatch(
       "evento/listPreventasEvento",
       this.evento.id
     );
     this.$store.dispatch(
       "evento/getpuntoventasEvento",
       this.evento.id
     );
     this.$store.dispatch(
       "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
       this.evento.id
     ); 
     this.mostrarmapa=true;
     if( this.evento.id_tipo_evento==7 ){
     this.mostrarmapa=false;
       }

     if (this.monedaSelectShopEvento === null && this.evento.monedas_evento.length > 0) {
       this.$store.commit(
         "evento/changeMonedaShopEvento",
         this.evento.monedas_evento[0].codigo_moneda
       );
       this.monedaSelectShop = this.monedaSelectShopEvento;
     } else {
       this.monedaSelectShop = this.monedaSelectShopEvento;
     }
     this.$store.dispatch("evento/getImagenesEvento", this.evento.id);
     $("html, body").animate({ scrollTop: $('body').offset().top }, 1);
     this.activeModalAsientos = false;
     if(this.evento && this.evento.slug != this.$route.params.evento){
       this.$store.dispatch(
         "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
         this.evento.id
       );
       this.$store.dispatch(
         "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
         this.evento.id
       );
     }
     if (this.idLocalidad && this.evento.localidades_evento) {
       let element = this.evento.localidades_evento.find(
         (e) => e.id_localidad == parseInt(this.idLocalidad)
       );
       if (!element) {
         this.idLocalidad = "";
       }
     }
    
     
   }
 },




},
async beforeCreate() {
 this.$store.dispatch("ciudad/listCiudades");
 await this.$store.dispatch("auditorio/listAuditorios");
 //await this.$store.dispatch("evento/getEvento", this.$route.params.evento);
 await this.$store.dispatch("evento/listEventosStatus", {
   page: 1,
   status: 1,
 });
 
  
},
async mounted() {

    await axios.get(configApi.apiUrl+'bnc/pagos/resumen/'+this.$route.params.token, headers)
        .then((response) => {    


                  //    console.log(response.data[0]);
            this.datos_compra.orden = response.data[0].token_refventa
            this.datos_compra.nombre_cliente = response.data[0].nombre + ' ' + response.data[0].apellidos
            this.datos_compra.res_banco = response.data[0].active
            this.datos_compra.id_venta = response.data[0].id
            this.datos_compra.fecha_transaccion = response.data[0].fecha
            this.datos_compra.referencia_orden =  response.data[0].token_refventa
            this.datos_compra.estado_transaccion = response.data[0].estado_entrega
            this.datos_compra.subtotal_compra = response.data[0].subtotal
            this.datos_compra.costo_transaccion = response.data[0].costo_transaccion
            this.datos_compra.total_compra =  response.data[0].total

            this.datos_compra.bs_subtotal =  response.data[0].bs_subtotal1
            this.datos_compra.bs_servicio =  response.data[0].bs_servicio1
            this.datos_compra.bs_iva =  response.data[0].bs_impuesto3
            this.datos_compra.bs_impuesto =  response.data[0].bs_impuesto1
            this.datos_compra.bs_impuesto2 = response.data[0].bs_impuesto2
            this.datos_compra.bs_total = response.data[0].bs_total1
            


        }).catch((e) => {
          console.log(e)
        })   
        this.clearCantBoleta();


 $("html, body").animate({ scrollTop: $('body').offset().top }, 1);
 await this.onMounted();
 
 

},
beforeUpdate() {
   

 if (this.idLocalidad != "") {
   document.getElementsByClassName(
     "cont-mapa-asientos"
   )[0].style.backgroundImage = "";
 }
},

methods: {

  
  currencyFormatDE (
      num1,
      thousandsSeparator = ',',
      decimalSeparator = '.',
      showDecimals = false
    ) {

      let num =parseFloat(num1);
      let numero = 0;
      if (num) {
        numero = num
          .toFixed(showDecimals ? '2' : '0')
          .replace('.', decimalSeparator) // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandsSeparator}`)
      }
      return numero
    },

    formatNumber(number) {
        return new Intl.NumberFormat("es-CO", {
            minimumFractionDigits: 0,
        }).format(number);
        },
 async onMounted() {
   
   
   
 },

 async clearCantBoleta() {
   this.$store.state.carrito.descuentocupon = 0;
   this.$store.state.carrito.cuponestado =0;
   this.$store.state.carrito.vservicio =0;
   this.$store.state.carrito.cupon  = '';
   this.$store.state.carrito.cuponok=false
   this.$store.state.funcioneshora=[]; 
   if (this.preciosBoletasCarrito && this.preciosBoletasCarrito.length > 0) {
     var lasBoletas = [];
     var losPalcos = [];


       lasBoletas = JSON.parse(localStorage.getItem('newboletasclear'));
       losPalcos = JSON.parse(localStorage.getItem('newpalcosclear'));

     /*await this.$store.dispatch("carrito/setNullReservas", {
       boletas: lasBoletas,
       palcos: losPalcos,
     });*/

     localStorage.removeItem('newboletasclear'); 
     localStorage.removeItem('newpalcosclear');

     this.idLocalidad = "";
     this.$store.dispatch("evento/clearCantBoleta");
     this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
     this.$store.dispatch("carrito/dateTimeSaleStart", null);
     this.$store.dispatch("carrito/dateTimeSaleEnd", null);
     
     this.$store.state.showModalFinalizarCompra = false;
     localStorage.removeItem('estadoVuex');
    
   } else {
     var lasBoletas = [];
     var losPalcos = [];
     this.idLocalidad = "";
     this.$store.dispatch("evento/clearCantBoleta");
   
     localStorage.removeItem('estadoVuex');
     this.$store.state.showModalFinalizarCompra = false;
     
   }
 },
 



 concatTextMoneda(item) {
   return `(${item.moneda.simbolo}) ${item.moneda.descripcion}`;
 },
 


},
computed: {

 hoy() {
   return moment().format("Y-MM-DD");
 },
 cantBoleta() {
   return this.$store.getters["evento/cantBoleta"];
 },
 cantBoletaTotal() {
   return this.$store.getters["evento/cantBoletaTotal"];
 },
 ciudades() {
   return this.$store.getters["ciudad/listCiudades"];
 },
 evento() {
   return this.$store.getters["evento/evento"];
 },
 condicionesEvento() {
   return this.$store.getters["evento/condicionesEvento"];
 },
 preventasEvento() {
   return this.$store.getters["evento/listPreventasEvento"];
 },
 showEventosDestacados() {
   return this.$store.getters["evento/listEventosDestacados"];
 },
 eventosDestacados() {
   return this.showEventosDestacados.slice(0, 4);
 },
 imagenesEvento() {
   return this.$store.getters["evento/imagenesEvento"];
 },
 userAuth() {
   return this.$store.getters["user/dataUserLogged"];
 },
 puntoventasEvento() {
   return this.$store.getters["evento/puntoventasEvento"];
 },
 
 listLocalidadesEventoAuditorio_mapeado() {
   return this.$store.getters[
     "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
   ];
 },

 imagenesEventoCarousel() {
   let imagenesEventoCarouselShow = [];
   if (this.imagenesEvento) {
     imagenesEventoCarouselShow = this.imagenesEvento.filter((imagen) => {
       return imagen.imagen.nombre.includes("banner");
     });
   }
   return imagenesEventoCarouselShow;
 },
 monedaSelectShopEvento() {
   return this.$store.getters["evento/monedaSelectShop"];
 },
 indexMonedaSelectShopEvento() {
   if (this.evento.monedas_evento && this.evento.monedas_evento.length > 0) {
     var index = this.evento.monedas_evento.findIndex(
       (moneda) => moneda.codigo_moneda === this.monedaSelectShopEvento
     );
     if (index === 0) {
       return "";
     } else {
       return index + 1;
     }
   }
 },
 simboloMoneda() {
   if (
     this.indexMonedaSelectShopEvento === "" ||
     this.indexMonedaSelectShopEvento === 0
   ) {
     return this.evento ? this.evento.monedas_evento[0].moneda.simbolo : ''
   } else {
     return this.evento ? this.evento.monedas_evento[this.indexMonedaSelectShopEvento - 1] : ''
       .moneda.simbolo;
   }
 },
 preciosBoletasCarrito() {
   return this.$store.getters["carrito/preciosBoletasCarrito"];
 },
 totalValorBoletasCarrito() {
   var preciosBoletasCarritoMonedaActual = this.$store.getters[
     "carrito/preciosBoletasCarrito"
   ].filter((item) => item.codigo_moneda == this.monedaSelectShopEvento);
   if (
     preciosBoletasCarritoMonedaActual &&
     preciosBoletasCarritoMonedaActual.length > 0
   ) {
     var total = 0;
     for (
       var i = preciosBoletasCarritoMonedaActual.length - 1;
       i >= 0;
       i--
     ) {
       total += preciosBoletasCarritoMonedaActual[i].precio;
     }
     return this.formatNumber(total);
   } else {
     return 0;
   }
 },
 localidadSelectCarrito() {
   return this.$store.getters["carrito/localidadSelectCarrito"];
 },
 countBoletas() {
   return this.$store.getters["carrito/countBoletas"];
    
 },
 countPalcos() {
   return this.$store.getters["carrito/countPalcos"];
   
 },
 dateTimeSaleStart() {
   return this.$store.getters["carrito/dateTimeSaleStart"];
 },
 dateTimeSaleEnd() {
   return this.$store.getters["carrito/dateTimeSaleEnd"];
 },

},
};
</script>
<style>
.container-payment{
    width: 100vw;
    height: 100vh;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.tabla_resumen{
    widows: 100%;
    margin-right: auto;
    margin-left: auto;
    
	border: 1px solid #CCCCCC;
    border-radius: 10px;
}
.tabla_resumen tr td{
    font-family:Verdana;
}
</style>